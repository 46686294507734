import React from 'react';

function Access() {
  return (
    <section id="access" className="pt-32 mb-20">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">Access</h2>
      <div className="p-4 bg-white shadow-md rounded-md">
        <div className="mb-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.9398944051586!2d139.7261466514941!3d35.70309668009218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cfecbaa404d%3A0x6b08751faa790dac!2z44CSMTYyLTA4NTIg5p2x5Lqs6YO95paw5a6_5Yy65Y2X5qaO55S677yX!5e0!3m2!1sja!2sjp!4v1681010316554!5m2!1sja!2sjp"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="border-0"
          ></iframe>
        </div>
        <table className="table-auto w-full text-left border border-gray-300">
          <tbody>
            <tr>
              <th className="px-4 py-2 bg-gray-600 text-white border border-gray-300">住所</th>
              <td className="px-4 py-2 border border-gray-300">〒162-0852 東京都新宿区南榎町7</td>
            </tr>
            <tr>
              <th className="px-4 py-2 bg-gray-600 text-white border border-gray-300">電話番号</th>
              <td className="px-4 py-2 border border-gray-300">
                <a href="tel:090-1768-2282" className="text-blue-500 hover:underline">
                  090-1768-2282
                </a>
              </td>
            </tr>
            <tr>
              <th className="px-4 py-2 bg-gray-600 text-white border border-gray-300">Email</th>
              <td className="px-4 py-2 border border-gray-300">
                <a href="mailto:juntailangantian997@gmail.com" className="text-blue-500 hover:underline">
                  juntailangantian997@gmail.com
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default Access;
