import React from 'react';

function Heading() {
  return (
    <section className="mt-32 py-12 px-6 bg-gray-600 text-white rounded-md shadow-md text-center">
      {/* 見出し部分 */}
      <h1 className="text-4xl sm:text-5xl font-bold mb-6">
        <span className="block sm:inline">あなたのお店の</span>
        <span className="block sm:inline">ホームページを</span>
        <span className="block sm:inline">お作りいたします！</span>
      </h1>

      {/* 説明文部分 */}
      <p className="text-lg sm:text-xl mb-8">
        <span className="block sm:inline">初期費用0円・完全月額制で</span>
        <span className="block sm:inline">シンプルで見やすいサイトを提供します。</span>
        <span className="block sm:inline">面倒な作業はすべてお任せください！</span>
      </p>

      {/* サービス内容リスト */}
      <ul className="text-left space-y-3 text-lg mx-auto max-w-md">
        <li>✅ ホームページ制作（静的ページ）</li>
        <li>✅ スマホ表示対応</li>
        <li>✅ ドメイン取得代行・設定</li>
        <li>✅ 安心・安全なURL（https://〜）を使用</li>
        <li>✅ 定期的な更新作業</li>
        <li>✅ メニューや写真の変更対応</li>
        <li>✅ 営業時間変更、テキスト修正</li>
        <li>✅ 契約期間の縛りなし、いつでも解約可能</li>
        <li>✅ 初期費用0円の安心プラン</li>
      </ul>

      {/* 注意書き */}
      <p className="text-sm text-gray-300 mt-8">
        ※大幅なデザイン変更や高度な機能追加（予約システムなど）は含まれません<br/>
        ※お支払いは12ヶ月分ご一括となりますが、ご解約時は月割りで返金いたします
      </p>
    </section>
  );
}

export default Heading;
