import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-red-700 text-white p-4 fixed w-full z-10 top-0">
      <div className="container mx-auto flex justify-between items-center max-w-screen-xl">
        <div>
          <Link smooth to="/" className="text-4xl font-bold no-underline hover:no-underline">
            Shuntaro Kishida
          </Link>
          <p className="text-sm font-light mt-1">Website Development Services</p>
        </div>
        <button className="md:hidden text-4xl focus:outline-none" onClick={toggleMenu}>{isOpen ? '✕' : '☰'}</button>
        <nav className="hidden md:flex">
          <Link smooth to="/#plans" className="mr-4 no-underline hover:underline">Plans</Link>
          <Link smooth to="/#about" className="mr-4 no-underline hover:underline">About Me</Link>
          <Link smooth to="/#access" className="mr-4 no-underline hover:underline">Access</Link>
        </nav>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-blue-700 flex flex-col justify-center items-center text-4xl">
          <button
            className="absolute top-4 right-4 text-4xl focus:outline-none"
            onClick={toggleMenu}
          >
            ✕
          </button>
          <Link smooth to="/#plans" className="mr-4 no-underline hover:underline" onClick={toggleMenu}>Plans</Link>
          <Link smooth to="/#about" className="my-4 no-underline hover:underline" onClick={toggleMenu}>About Me</Link>
          <Link smooth to="/#access" className="my-4 no-underline hover:underline" onClick={toggleMenu}>Access</Link>
        </div>
      )}
    </header>
  );
}

export default Header;
