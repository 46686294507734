import React from 'react';
import Heading from './Heading';
import Plans from './Plans';
import AboutUs from './AboutUs';
import Access from './Access';
import ScrollToTopButton from './ScrollToTopButton';
import { HashLink as Link } from 'react-router-hash-link';

function Home() {
  return (
    <div className="relative">
      {/* トップ画像セクション */}
      <section id="top-image" className="relative h-screen overflow-hidden">
        {/* 背景用の固定画像 */}
        <img
          src="/images/company_top2.jpg"
          alt="Top"
          className="absolute top-0 left-0 w-full h-full object-cover"
          style={{
            position: 'fixed', // 背景として固定
            zIndex: '-1', // 背面に配置
          }}
        />

        {/* 下矢印ボタン */}
        <div className="absolute bottom-24 md:bottom-10 left-1/2 transform -translate-x-1/2 z-10">
          <Link smooth to="/#heading"
            className="flex items-center justify-center w-24 h-16 bg-white rounded-full shadow-lg hover:bg-gray-200 transition duration-300 cursor-pointer"
          >
            <span className="text-red-700 text-4xl font-bold animate-bounce">↓</span>
          </Link>
        </div>
      </section>

      {/* コンテンツ */}
      <div className="bg-gray-100">
        <div
          id="heading"
          className="container mx-auto max-w-screen-xl p-4 mt-screen relative z-10"
        >
          <Heading />
          <Plans />
          <AboutUs />
          <Access />
        </div>

        {/* ▲Topボタン */}
        <ScrollToTopButton />
      </div>
    </div>
  );
}

export default Home;
